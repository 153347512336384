import {
  convertLengthMetersToFeet,
  convertLengthFeetToMeters,
} from "utils-library/dist/utils";

import {
  IValidateDataEngineRules,
  validateDataMethods,
} from "utils-library/dist/validation-engine";
import {IGeoPosition} from "utils-library/dist/geo-interfaces";

import {
  IWorkzone,
  getDefaultWorkzone,
  validateWorkzoneForBroadcast as workzoneValidateWorkzoneForBroadcast,
  EWZBroadcastSelectionMode,
  EWZClosureSelectionMode,
  EWZClosureType,
  EWZSimpleClosureTypeLane,
  EWZSimpleClosureTypeShoulder,
  EWZSimpleClosureTypeReducedSpeed,
  EWZSimpleClosureTypeLeftTurn,
  EWZSimpleClosureTypeRightTurn,
  EWZSimpleClosureTypeHeightLimit,
  EWZClosureTypeDeprecated,
  EWZLane,
  EWZShoulder,
  EWZHeading,
  IGeoLine,
  IGeoPolygon,
} from "mhc-server/dist/interfaces";

export interface IUIWorkzone {
  id: string; // PK
  kapschId: number;
  active: boolean;

  name: string;
  start: Date;
  end: Date;
  forEver: boolean;

  closureSelectionMode: EWZClosureSelectionMode;
  closureType:
    | EWZClosureType
    | EWZSimpleClosureTypeLane
    | EWZSimpleClosureTypeShoulder
    | EWZSimpleClosureTypeReducedSpeed
    | EWZSimpleClosureTypeLeftTurn
    | EWZSimpleClosureTypeRightTurn
    | EWZClosureTypeDeprecated
    | EWZSimpleClosureTypeHeightLimit;
  itisCodes: number[];
  closedLane: EWZLane;
  closedShoulder: EWZShoulder;
  speedLimitInMiles: number;

  closedLaneWidthInFeet: number;
  workersPresent: boolean;
  notes: string;

  // Point
  point: IGeoPosition;

  // Path
  path: IGeoLine;

  heading: EWZHeading[];

  broadcast: {
    selectionMode: EWZBroadcastSelectionMode;
    selectionByRegion: {
      region: IGeoPolygon;
    };
    selectionByRadius: {
      // Referenced to `point`
      radiusInMeters: number;
    };
  };

  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
  deletedAt: number;
  deletedBy: string;
  archivedAt: number;
  archivedBy: string;
}

export const convertIWorkzoneToIUIWorkzone = (workzone: IWorkzone): IUIWorkzone => {
  return {
    id: workzone.id,
    kapschId: workzone.kapschId,
    active: workzone.active,
    name: workzone.name,
    start: workzone.start,
    end: workzone.end,
    forEver: workzone.forEver,

    closureSelectionMode: workzone.closureSelectionMode,
    closureType: workzone.closureType,
    itisCodes: workzone.itisCodes,
    closedLane: workzone.closedLane,
    closedShoulder: workzone.closedShoulder,
    speedLimitInMiles: workzone.speedLimitInMiles,

    closedLaneWidthInFeet: convertLengthMetersToFeet(workzone.closedLaneWidthInMeters),
    workersPresent: workzone.workersPresent,
    notes: workzone.notes,

    point: workzone.point,

    path: workzone.path,

    heading: workzone.heading,

    broadcast: workzone.broadcast,

    createdAt: workzone.createdAt,
    createdBy: workzone.createdBy,
    updatedAt: workzone.updatedAt,
    updatedBy: workzone.updatedBy,
    deletedAt: workzone.deletedAt,
    deletedBy: workzone.deletedBy,
    archivedAt: workzone.archivedAt,
    archivedBy: workzone.archivedBy,
  };
};

export const convertIUIWorkzoneToIWorkzone = (uiWorkzone: IUIWorkzone): IWorkzone => {
  return {
    id: uiWorkzone.id,
    kapschId: uiWorkzone.kapschId,
    active: uiWorkzone.active,
    name: uiWorkzone.name,
    start: uiWorkzone.start,
    end: uiWorkzone.end,
    forEver: uiWorkzone.forEver,

    closureSelectionMode: uiWorkzone.closureSelectionMode,
    closureType: uiWorkzone.closureType,
    itisCodes: uiWorkzone.itisCodes,
    closedLane: uiWorkzone.closedLane,
    closedShoulder: uiWorkzone.closedShoulder,
    speedLimitInMiles: uiWorkzone.speedLimitInMiles,

    closedLaneWidthInMeters: convertLengthFeetToMeters(uiWorkzone.closedLaneWidthInFeet),
    workersPresent: uiWorkzone.workersPresent,
    notes: uiWorkzone.notes,

    point: uiWorkzone.point,

    path: uiWorkzone.path,

    heading: uiWorkzone.heading,

    broadcast: uiWorkzone.broadcast,

    createdAt: uiWorkzone.createdAt,
    createdBy: uiWorkzone.createdBy,
    updatedAt: uiWorkzone.updatedAt,
    updatedBy: uiWorkzone.updatedBy,
    deletedAt: uiWorkzone.deletedAt,
    deletedBy: uiWorkzone.deletedBy,
    archivedAt: uiWorkzone.archivedAt,
    archivedBy: uiWorkzone.archivedBy,
  };
};

export const getDefaultUIWorkzone = (): IUIWorkzone => convertIWorkzoneToIUIWorkzone(getDefaultWorkzone());

export const uiWorkzoneValidationRulesForBroadcast = ((): IValidateDataEngineRules<IUIWorkzone> => {
  const validationRules: IValidateDataEngineRules<IUIWorkzone> = {...workzoneValidateWorkzoneForBroadcast} as any;
  validationRules.closedLaneWidthInFeet = (closedLaneWidthInFeet: any, workzone: any): string => (
    validateDataMethods.isNumber(closedLaneWidthInFeet) ||
    workzoneValidateWorkzoneForBroadcast.closedLaneWidthInMeters(convertLengthFeetToMeters(closedLaneWidthInFeet), workzone) ||
    ""
  );
  validationRules.id = () => ""; // Do not validate it since the api will assign it anyway.
  delete validationRules.closedLaneWidthInMeters;
  return validationRules;
})();
