/*
This optional code is used to register a service worker.
register() is not called by AppServiceWorker.

This lets the app load faster on subsequent visits in production, and gives
it offline capabilities. However, it also means that developers (and users)
will only see deployed updates on subsequent visits to a page, after all the
existing tabs open on the page have been closed, since previously cached
resources are updated in the background.

To learn more about the benefits of this model and instructions on how to
opt-in, read https://cra.link/PWA
*/

import {SWChannelClient} from "./utils/sw-channel/SWChannelClient";

//#region "Init"

const swChannel = new SWChannelClient();

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const appVersion = "0.0.710"; // Updated by updateClientAppWithRepoVersion.js

const consolePrefix = `service-registration-v${appVersion}:`;

//#endregion "Init"

//#region "Register / Unregister worker"

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const register = (config?: Config): void => {
  if (process.env.NODE_ENV !== 'production') return;  // Not in production mode
  if (!('serviceWorker' in navigator)) return;        // Service worker is not supported by the browser

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL || '', window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // From what our page is served on. This might happen if a CDN is used to
    // Serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  window.addEventListener('load', () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the
      // Service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          consolePrefix,
          'This web app is being served cache-first by a service ' +
          'worker. To learn more, visit https://cra.link/PWA',
        );
      });
    }
    else {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config);
    }
  });
};

const registerValidSW = (swUrl: string, config?: Config): void => {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker === null) return;

        installingWorker.onstatechange = async () => {
          if (installingWorker.state !== 'installed') return; // Exit is not in installed phase

          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // But the previous service worker will still serve the older
            // Content until at least on Tab left opened
            // Note: Be default the CRA needs all to be closed https://cra.link/PWA but this has been improved.

            await swChannel.sendToWaitingWorker({type: 'PREPARE_CACHES_FOR_UPDATE'});

            console.log(
              consolePrefix,
              'New content is available and will be used when at least one tab left opened.',
            );

            // Execute callback
            config?.onUpdate?.(registration);
          }
          else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            console.log(
              consolePrefix,
              'Content is cached for offline use.',
            );

            // Execute callback
            config?.onSuccess?.(registration);
          }
        };
      }; // Registration on update found
    })
    .catch((error) => {
      console.error(
        consolePrefix,
        'Error during service worker registration:',
        error,
      );
    });
};

const checkValidServiceWorker = (swUrl: string, config?: Config): void => {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {headers: {'Service-Worker': 'script'}})
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      }
      else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(error => {
      console.warn(
        consolePrefix,
        'No internet connection found. App is running in offline mode.',
        error,
      );
    });
};

export const unregister = (): void => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(
          consolePrefix,
          'unregister(): ',
          error.message,
        );
      });
  }
};

//#endregion "Register / Unregister worker"

//#region "Skip waiting new version when needs update"

window.addEventListener('beforeunload', async () => {
  return swChannel.sendToWaitingWorker({
    throwErrorOnNoWorker: false,
    type: 'SKIP_WAITING_WHEN_SOLO',
  });
});

//#endregion "Skip waiting new version when needs update"
